import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Thanks.module.css';
import Header from '../../components/Header/Header';
import computerclick from '../../assets/website.svg';
import logoText from '../../assets/logo-text.png';
import thankyou from '../../assets/thankyou.png';
import copyLogo from '../../assets/copy-logo.png';

const Thanks: FC = () => {
  const [timeoutState, setTimeoutState] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();

  const initTimer = () => {
    if (timeoutState) {
      clearTimeout(timeoutState);
    }

    setTimeoutState(() =>
      setTimeout(() => {
        navigate('/');
      }, 10000)
    );
  };

  useEffect(() => {
    initTimer();
  }, []);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.innerContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.insightsContainer}>
            <img
              className={classes.imgFluid}
              src={thankyou}
              alt="thank you for sharing your insights"
            />
          </div>
        </div>
        <div className={classes.subtitle}>
          For more information discover the...
        </div>
        <div className={classes.gskLearningContainer}>
          <div className={classes.imgGskContainer}>
            <img className={classes.imgFluid} src={logoText} alt="GSK legend" />
          </div>
        </div>
        <div className={classes.subtitle}>
          The GSK PLACE Hub includes English and Spanish tool and resources to
          <div>help educate, activate and empower patients.</div>
        </div>
        <div className={classes.shareText}>
          Share the GSK PLACE Hub with your patients.
        </div>
      </div>

      <a href="http://gskplacehub.com/" className={classes.subtitle2}>
        <img
          className={classes.computerClick}
          src={computerclick}
          alt="website address"
        />
      </a>
      <div className={classes.copyLogoContainer}>
        <p className={classes.copyrightText}>
          Trademarks are owned by or licensed to the GSK group of companies.
        </p>
        <div className={classes.copyImageContainer}>
          <img
            src={copyLogo}
            className={classes.imgFluid}
            alt="2022 GSK copyright"
          />
        </div>
      </div>
    </div>
  );
};

export default Thanks;
