import React, { FC } from 'react';
import { questions } from '../../utils/questions';
import css from './ProgressBar.module.css';

interface Props {
  bgcolor: string;
  progress: string;
  height: number;
  indice: number;
}

const ProgressBar: FC<Props> = ({ bgcolor, progress, height, indice }) => {
  const Parentdiv = {
    height,
    width: '86%',
    borderRadius: 40,
    backgroundColor: '#D6D6D6',
    margin: '0.5rem',
    position: 'relative' as any,
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right' as any,
  };

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900,
  };

  const circlesdiv = {
    position: 'absolute' as any,
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  };

  const trianglediv = {
    position: 'absolute' as any,
    top: '2.6rem',
    transform: 'translateY(-50%)',
    left: 0,
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  };

  const circles = {
    width: '0.55rem',
    height: '0.55rem',
    backgroundColor: 'white',
    border: '3px solid #F36633',
    borderRadius: '50px',
  };

  return (
    <div style={Parentdiv}>
      <div style={circlesdiv}>
        {questions.map((el) => {
          return <span key={el.text2} style={circles} />;
        })}
      </div>
      <div style={trianglediv}>
        {questions.map((el, idx) => {
          return (
            <span
              key={el.text2}
              style={{ opacity: indice === idx ? 1 : 0 }}
              className={css.triangle}
            />
          );
        })}
      </div>

      <div style={Childdiv}>
        <span style={progresstext} />
      </div>
    </div>
  );
};

export default ProgressBar;
