/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-useless-fragment */
import { API, graphqlOperation } from 'aws-amplify';
import { useEffect } from 'react';
import Papa from 'papaparse';
import { listAnswers, listQuestionaries } from '../../graphql/queries';

const DownloadCSV = () => {
  const fileDownload = (content: string, fileName: string) => {
    // file download
    const pom = document.createElement('a');
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', fileName);
    pom.click();
  };

  const triggerDownload = async () => {
    const questionaries = (await API.graphql(
      graphqlOperation(listQuestionaries, { limit: 100000 })
    )) as any;
    const answers = (await API.graphql(
      graphqlOperation(listAnswers, { limit: 100000 })
    )) as any;

    const answersList = answers.data.listAnswers.items.map((answer: any) => {
      delete answer.updatedAt;
      delete answer.createdAt;
      return answer;
    });
    const questionariesList = questionaries.data.listQuestionaries.items.map(
      (questionary: any) => {
        delete questionary.updatedAt;
        return questionary;
      }
    );

    const csvAnswers = Papa.unparse(answersList, { delimiter: '|' });
    const csvQuestionaries = Papa.unparse(questionariesList, {
      delimiter: '|',
    });

    let totalCompleted: number = 0;
    let totalIncomplete: number = 0;
    const totalQuestionaries: number = questionariesList.length;

    questionariesList.forEach((questionary: any) => {
      if (questionary.finished) {
        totalCompleted += 1;
      } else {
        totalIncomplete += 1;
      }
    });

    const csvContent = `sep=|\r\nCompleted|Incomplete|Total\r\n${totalCompleted}|${totalIncomplete}|${totalQuestionaries}\r\n\r\n${csvQuestionaries}\r\n\r\n${csvAnswers}`;

    let csvContent1 = `sep=|\r\nCompleted|Incomplete|Total\r\n${totalCompleted}|${totalIncomplete}|${totalQuestionaries}\r\n\r\n`;
    questionariesList.forEach((questionary: any) => {
      const qAnswers = answersList.filter(
        (answer: any) => answer.questionaryId === questionary.id
      );
      if (qAnswers[0]) {
        csvContent1 = csvContent1.concat(
          `Date Filled|Finished|First Name|Last Name|Email\r\n${questionary.createdAt}|${questionary.finished}|${qAnswers[0].firstName}|${qAnswers[0].lastName}|${qAnswers[0].email}\r\n`
        );
        qAnswers.forEach((qAnswer: any) => {
          csvContent1 = csvContent1.concat(
            `Question|Answer\r\n${qAnswer.question}|${qAnswer.answer}\r\n`
          );
        });
        csvContent1 = csvContent1.concat('\r\n\r\n');
      }
    });

    fileDownload(csvContent, 'raw.csv');
    fileDownload(csvContent1, 'readable.csv');
  };

  useEffect(() => {
    triggerDownload();
  }, []);

  return <>Your download will begin shortly...</>;
};

export default DownloadCSV;
