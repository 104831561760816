/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      question
      answer
      questionaryId
      firstName
      lastName
      email
      middleName
      designation
      titleOrPosition
      organization
      address1
      address2
      address3
      city
      stateProvince
      zipPostalCode
      country
      regId
      createdAt
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        questionaryId
        firstName
        lastName
        email
        middleName
        designation
        titleOrPosition
        organization
        address1
        address2
        address3
        city
        stateProvince
        zipPostalCode
        country
        regId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionary = /* GraphQL */ `
  query GetQuestionary($id: ID!) {
    getQuestionary(id: $id) {
      id
      finished
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionaries = /* GraphQL */ `
  query ListQuestionaries(
    $filter: ModelQuestionaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        finished
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDebug = /* GraphQL */ `
  query GetDebug($id: ID!) {
    getDebug(id: $id) {
      id
      inputString
      encryptedType
      responseJson
      createdAt
      updatedAt
    }
  }
`;
export const listDebugs = /* GraphQL */ `
  query ListDebugs(
    $filter: ModelDebugFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDebugs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inputString
        encryptedType
        responseJson
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
