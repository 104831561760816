export const questions = [
  {
    text1: 'In an average year ',
    text2: 'I see this many patients with lupus.',
    options: ['0', 'Fewer than 5', '5-10', '11-20', 'More Than 20'],
  },
  {
    text1: 'In an average year ',
    text2: 'I see these types of lupus patients.',
    options: ['Systemic lupus erythematosus', 'Lupus nephritis'],
  },
  {
    text1: 'My lupus patients ',
    text2: 'present a good understanding of their condition.',
    options: [
      'Strongly Disagree',
      'Disagree',
      'Neither Agree nor Disagree',
      'Agree',
      'Strongly Agree',
      'Not Applicable',
    ],
  },
  {
    text1: 'My lupus patients ',
    text2: 'present a good understanding of their symptoms.',
    options: [
      'Strongly Disagree',
      'Disagree',
      'Neither Agree nor Disagree',
      'Agree',
      'Strongly Agree',
      'Not Applicable',
    ],
  },
  {
    text1: 'My lupus patients ',
    text2: 'present a good understanding of their treatment options.',
    options: [
      'Strongly Disagree',
      'Disagree',
      'Neither Agree nor Disagree',
      'Agree',
      'Strongly Agree',
      'Not Applicable',
    ],
  },
  {
    text1: 'My lupus patients ',
    text2: 'help me in their individual diagnosis and treatment.',
    options: [
      'Strongly Disagree',
      'Disagree',
      'Neither Agree nor Disagree',
      'Agree',
      'Strongly Agree',
      'Not Applicable',
    ],
  },
  {
    text1: 'My lupus patients ',
    text2: 'are well-prepared to advocate for their best interests.',
    options: [
      'Strongly Disagree',
      'Disagree',
      'Neither Agree nor Disagree',
      'Agree',
      'Strongly Agree',
      'Not Applicable',
    ],
  },
  {
    text1: 'My lupus patients ',
    text2: 'could benefit from a greater familiarity with their condition.',
    options: [
      'Strongly Disagree',
      'Disagree',
      'Neither Agree nor Disagree',
      'Agree',
      'Strongly Agree',
      'Not Applicable',
    ],
  },
  {
    text1: 'My lupus patients ',
    text2:
      'could benefit from a greater engagement in their own disease management.',
    options: [
      'Strongly Disagree',
      'Disagree',
      'Neither Agree nor Disagree',
      'Agree',
      'Strongly Agree',
      'Not Applicable',
    ],
  },
];
