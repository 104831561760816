import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DownloadCSV from '../DownloadCSV/DownloadCSV';
import Form from '../form/Form';
import Questionary from '../Questionary/Questionary';
import Thanks from '../Thanks/Thanks';
import Welcome from '../Welcome/Welcome';

export const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/encrypted" element={<Welcome />} />
      <Route path="/questionary/:questionNumber" element={<Questionary />} />
      <Route path="/thanks" element={<Thanks />} />
      <Route path="/form" element={<Form />} />
      <Route path="/download" element={<DownloadCSV />} />
    </Routes>
  </BrowserRouter>
);
