import { FC } from 'react';
import gsklogo from '../../assets/logo-gsk.svg';
import classes from './Header.module.css';
import insightsLogo from '../../assets/title-logo.png';

const Header: FC = () => {
  return (
    <div className={classes.header}>
      <div className={classes.insightsContainer}>
        <img
          className={classes.insightsLogo}
          src={insightsLogo}
          alt="your insights matter logo"
        />
      </div>
      <img src={gsklogo} alt="GSL logo" className={classes.gskLogo} />
    </div>
  );
};

export default Header;
