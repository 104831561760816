/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      question
      answer
      questionaryId
      firstName
      lastName
      email
      middleName
      designation
      titleOrPosition
      organization
      address1
      address2
      address3
      city
      stateProvince
      zipPostalCode
      country
      regId
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      question
      answer
      questionaryId
      firstName
      lastName
      email
      middleName
      designation
      titleOrPosition
      organization
      address1
      address2
      address3
      city
      stateProvince
      zipPostalCode
      country
      regId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      question
      answer
      questionaryId
      firstName
      lastName
      email
      middleName
      designation
      titleOrPosition
      organization
      address1
      address2
      address3
      city
      stateProvince
      zipPostalCode
      country
      regId
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionary = /* GraphQL */ `
  mutation CreateQuestionary(
    $input: CreateQuestionaryInput!
    $condition: ModelQuestionaryConditionInput
  ) {
    createQuestionary(input: $input, condition: $condition) {
      id
      finished
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionary = /* GraphQL */ `
  mutation UpdateQuestionary(
    $input: UpdateQuestionaryInput!
    $condition: ModelQuestionaryConditionInput
  ) {
    updateQuestionary(input: $input, condition: $condition) {
      id
      finished
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionary = /* GraphQL */ `
  mutation DeleteQuestionary(
    $input: DeleteQuestionaryInput!
    $condition: ModelQuestionaryConditionInput
  ) {
    deleteQuestionary(input: $input, condition: $condition) {
      id
      finished
      createdAt
      updatedAt
    }
  }
`;
export const createDebug = /* GraphQL */ `
  mutation CreateDebug(
    $input: CreateDebugInput!
    $condition: ModelDebugConditionInput
  ) {
    createDebug(input: $input, condition: $condition) {
      id
      inputString
      encryptedType
      responseJson
      createdAt
      updatedAt
    }
  }
`;
export const updateDebug = /* GraphQL */ `
  mutation UpdateDebug(
    $input: UpdateDebugInput!
    $condition: ModelDebugConditionInput
  ) {
    updateDebug(input: $input, condition: $condition) {
      id
      inputString
      encryptedType
      responseJson
      createdAt
      updatedAt
    }
  }
`;
export const deleteDebug = /* GraphQL */ `
  mutation DeleteDebug(
    $input: DeleteDebugInput!
    $condition: ModelDebugConditionInput
  ) {
    deleteDebug(input: $input, condition: $condition) {
      id
      inputString
      encryptedType
      responseJson
      createdAt
      updatedAt
    }
  }
`;
