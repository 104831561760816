import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API, graphqlOperation } from 'aws-amplify';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import { questions } from '../../utils/questions';
import classes from './Questionary.module.css';
import { createAnswer, createQuestionary } from '../../graphql/mutations';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

let t1 = new Date();

const Questionary: FC = () => {
  const { questionNumber } = useParams();
  const questionNumber1 = parseInt(questionNumber || '0', 10);
  const navigate = useNavigate();
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<
    number | number[] | undefined
  >();
  const [optionHistory, setOptionHistory] = useState<number[]>([]);
  const [progressBar, setProgressBar] = useState(10);
  const [radioOption, setRadioOption] = useState([false, false]);

  const updateOptionHistory = (newOptionHistory: Array<number>) => {
    sessionStorage.setItem('optionHistory', JSON.stringify(newOptionHistory));
    setOptionHistory(newOptionHistory);
  };

  const loadOptionHistory = () => {
    const optionsUnparsed = sessionStorage.getItem('optionHistory');
    const newOptionHistory = optionsUnparsed ? JSON.parse(optionsUnparsed) : [];
    setOptionHistory(newOptionHistory);
  };

  const submitSurvey = async (finished: boolean) => {
    try {
      const userDataUnparsed = sessionStorage.getItem('userData');
      const optionHistoryStoredUnparsed =
        sessionStorage.getItem('optionHistory');
      const userData = JSON.parse(userDataUnparsed as string);

      const optionHistoryStored = JSON.parse(
        optionHistoryStoredUnparsed as string
      );
      const questionaryCreation = (await API.graphql(
        graphqlOperation(createQuestionary, {
          input: { finished },
        })
      )) as any;
      const questionaryId = questionaryCreation.data.createQuestionary.id;
      const graphQLDataToSend = questions.map((question, index) => {
        let answerValue = '';
        if (optionHistoryStored[index].length > 0) {
          answerValue = optionHistoryStored[index].map((answer: number) => {
            return question.options[answer];
          });
        } else {
          answerValue = question.options[optionHistoryStored[index]];
        }

        const questionObj = {
          ...userData,
          question: `${question.text1}${question.text2}`,
          answer: answerValue.toString(),
          questionaryId,
        };

        return questionObj;
      });

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < graphQLDataToSend.length; index++) {
        const element = graphQLDataToSend[index];
        if (element.answer) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await API.graphql(
              graphqlOperation(createAnswer, {
                input: element,
              })
            );
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    sessionStorage.removeItem('optionHistory');
    sessionStorage.removeItem('userData');
  };

  const nextQuestion = () => {
    t1 = new Date();
    const newOptions = [...(optionHistory as Array<number>)];
    newOptions[questionNumber1] = selectedOption as number;
    updateOptionHistory(newOptions);
    const nextQuestionNumber = questionNumber1 + 1;
    if (nextQuestionNumber < questions.length) {
      navigate(`/questionary/${nextQuestionNumber}`);
    } else {
      submitSurvey(true);
      navigate('/thanks');
    }
  };

  useEffect(() => {
    setText1(questions[questionNumber1].text1);
    setText2(questions[questionNumber1].text2);
    setOptions(questions[questionNumber1].options);
    setSelectedOption(optionHistory[questionNumber1]);

    const correctIndex = questionNumber1 + 1;
    let progress = (correctIndex / questions.length) * 100;
    progress = progress === 100 ? 100 : progress - 6;
    setProgressBar(progress);
  }, [questionNumber, optionHistory]);

  useEffect(() => {
    loadOptionHistory();
    t1 = new Date();
    let timerInterval: NodeJS.Timer;
    const intervalHandle = setInterval(() => {
      const diff = new Date().getTime() - t1.getTime();
      const INTERACTION_TIMEOUT = 30000;
      const REDIRECT_TIMEOUT = 15000;
      if (diff >= INTERACTION_TIMEOUT) {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: 'Are you still there?',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Continue',
            cancelButtonColor: '#EE7623',
            didOpen: () => {
              const b = Swal.getHtmlContainer()?.querySelector('b');
              timerInterval = setInterval(() => {
                if (b) {
                  const timeLeft = Math.ceil((Swal.getTimerLeft() || 0) / 1000);
                  b.textContent = `${timeLeft}`;
                }
              }, 1000);
            },
            didClose: () => {
              t1 = new Date();
            },
            timer: REDIRECT_TIMEOUT,
            timerProgressBar: true,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              submitSurvey(false);
              navigate('/');
            }
          });
        }
      }
    }, 1000);
    return () => {
      clearTimeout(intervalHandle);
      clearTimeout(timerInterval);
    };
  }, []);

  useEffect(() => {
    const optionsSelected: number[] = [];
    options.forEach((el, i) => {
      if (radioOption[i]) {
        optionsSelected.push(i);
      }
    });

    setSelectedOption(optionsSelected.length > 0 ? optionsSelected : undefined);
  }, [radioOption]);

  const radioSelectedHandle = (index: number) => {
    setRadioOption((prev) =>
      prev.map((item, idx) => (idx === index ? !item : item))
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.barContainer}>
        <ProgressBar
          bgcolor="#F36633"
          progress={`${progressBar}`}
          indice={questionNumber1}
          height={8}
        />
      </div>
      <div className={classes.innerContainer}>
        <Header />
        <div className={classes.contentContainer}>
          {/* QuestionText */}
          <div className={classes.questionContainer}>
            <div className={classes.question}>
              <div className={classes.text1}>{text1}</div>
              <div className={classes.text2}> {text2}</div>
              {questionNumber1 === 1 && (
                <div className={classes.instructions}>
                  (Click all that apply)
                </div>
              )}
            </div>
          </div>
          {/* Options */}
          {questionNumber1 === 1 ? (
            <div className={classes.radioContainer}>
              <div>
                <div className={classes.flexStart}>
                  <button
                    className={
                      radioOption[0]
                        ? classes.optionButtonSelected
                        : classes.optionButton
                    }
                    onClick={() => radioSelectedHandle(0)}
                    type="button"
                    aria-label="option"
                  />
                  <span className={classes.radioText}>{options[0]}</span>
                </div>

                <div className={classes.flexStart}>
                  <button
                    className={
                      radioOption[1]
                        ? classes.optionButtonSelected
                        : classes.optionButton
                    }
                    onClick={() => radioSelectedHandle(1)}
                    type="button"
                    aria-label="option"
                  />
                  <span className={classes.radioText}>{options[1]}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.optionsContainer}>
              <div className={classes.lineContainer}>
                <div className={classes.line} />
              </div>
              <div className={classes.buttonsContainer}>
                <div className={classes.buttonsInnerContainer}>
                  {options.map((option, index) => (
                    <div key={option}>
                      <button
                        className={
                          selectedOption === index
                            ? classes.optionButtonSelected
                            : classes.optionButton
                        }
                        onClick={() => setSelectedOption(index)}
                        type="button"
                        aria-label={option}
                      />
                      <div className={classes.buttonText}>{option}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Continue button */}
        <div className={classes.continueButtonContainer}>
          <Button
            onClick={nextQuestion}
            disabled={selectedOption === undefined}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Questionary;
