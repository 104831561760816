import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import classes from './Form.module.css';

const Form = () => {
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    const emailRegex = '^[^@]+@[^@]+.[a-zA-Z]{2,}$';

    if (firstname && lastname && email.match(emailRegex) != null) {
      setIsEnabled(false);
    } else {
      setIsEnabled(true);
    }
  }, [firstname, lastname, email]);

  const submit = (e: React.FormEvent<HTMLFormElement>): any => {
    e.preventDefault();

    const userObject = {
      firstName: firstname,
      lastName: lastname,
      email,
    };
    sessionStorage.setItem('userData', JSON.stringify(userObject));
    navigate('/questionary/0');
  };
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.formContainer}>
        <form onSubmit={submit} id="userForm">
          <div className={classes.inputsContainer}>
            <div className={classes.flexStart}>
              <div className={classes.firstNameContainer}>
                <div className={classes.textFields}>First name</div>
                <input
                  id="firstname"
                  className={classes.input}
                  minLength={2}
                  required
                  type="text"
                  name="firstname"
                  placeholder="Tap here to enter"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className={classes.lastNameContainer}>
                <div className={classes.textFields}>Last name</div>
                <input
                  className={classes.input}
                  minLength={2}
                  required
                  type="text"
                  name="lastName"
                  placeholder="Tap here to enter"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={classes.flexStart}>
              <div className={classes.emailContainer}>
                <span className={classes.textFields}>Email address</span>
                <input
                  className={classes.emailField}
                  type="text"
                  name="email"
                  placeholder="Tap here to enter"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className={classes.btnContainer}>
                <button
                  className={
                    isEnabled ? classes.disabledButton : classes.btnSubmit
                  }
                  disabled={isEnabled}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
        {isEnabled && email.length > 6 && (
          <div className={classes.validationMessage}>
            Please fill out the form
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
