/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import classes from './Button.module.css';

interface Props {
  onClick?: () => any;
  disabled?: boolean;
  children?: ReactNode;
  type?: string;
}

const Button = ({ children, onClick, disabled, type }: Props) => {
  return (
    <button
      onClick={onClick}
      type={(type || 'button') as any}
      disabled={disabled}
      className={disabled ? classes.disabledButton : classes.continueButton}
    >
      {children}
    </button>
  );
};

export default Button;
