/* eslint-disable jsx-a11y/no-autofocus */
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import classes from './Welcome.module.css';
import gsklogo from '../../assets/logo-gsk.svg';
import Button from '../../components/Button/Button';

import { createDebug } from '../../graphql/mutations';
import insightTitle from '../../assets/logo-insights.svg';
import scanImg from '../../assets/scanbtn.png';

const Welcome: FC = () => {
  const navigate = useNavigate();
  const [inputScan, setInputScan] = useState('');

  const beginButton = () => {
    sessionStorage.removeItem('optionHistory');
    sessionStorage.removeItem('userData');
    navigate('/form');
  };

  const sendScannerReading = (e: any) => {
    if (e.key === 'Enter') {
      const dataArray = inputScan.split('|');
      if (dataArray.length === 15) {
        const userObject = {
          regId: dataArray[0],
          firstName: dataArray[1],
          middleName: dataArray[2],
          lastName: dataArray[3],
          designation: dataArray[4],
          titleOrPosition: dataArray[5],
          organization: dataArray[6],
          address1: dataArray[7],
          address2: dataArray[8],
          address3: dataArray[9],
          city: dataArray[10],
          stateProvince: dataArray[11],
          zipPostalCode: dataArray[12],
          country: dataArray[13],
          email: dataArray[14],
        };

        try {
          API.graphql(
            graphqlOperation(createDebug, {
              input: {
                inputString: inputScan,
              },
            })
          );
          // eslint-disable-next-line no-empty
        } catch (error) {}
        sessionStorage.removeItem('optionHistory');
        sessionStorage.removeItem('userData');
        sessionStorage.setItem('userData', JSON.stringify(userObject));
        navigate('/questionary/0');
      }
    }
  };

  useEffect(() => {
    const autoFocus = setInterval(() => {
      const el = document.getElementById('scanInput') as any;
      el.value = '';
      el.focus();
    }, 2000);
    return () => {
      clearInterval(autoFocus);
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img src={gsklogo} alt="GSK logo" className={classes.gskLogo} />
      </div>
      <div className={classes.innerContainer}>
        <div className={`${classes.titleContainer} ${classes.fadeInAndOut}`}>
          <div className={classes.insights}>
            <img
              className={classes.insightsImg}
              src={insightTitle}
              alt="Insights title"
            />
          </div>
        </div>
        <div className={classes.subtitleContainer}>
          <div className={classes.subtitle}>
            Please take this brief survey about your experience,
          </div>
          <div className={classes.subtitle}>
            so we can better understand, educate and help your patients.
          </div>
        </div>
      </div>

      <div className={classes.problemContainer}>
        <Button onClick={beginButton}>Problem Scanning?</Button>
      </div>

      <div className={classes.scanButtonContainer}>
        <button className={classes.scanButton} type="button">
          <img src={scanImg} className={classes.scanImg} alt="Scan button" />
        </button>
      </div>
      <input
        id="scanInput"
        value={inputScan}
        className={classes.inputScanner}
        type="text"
        onChange={(e) => setInputScan(e.target.value)}
        onKeyDown={(e) => sendScannerReading(e)}
        autoFocus
        autoComplete="off"
      />
    </div>
  );
};

export default Welcome;
