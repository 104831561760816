import { FC } from 'react';
import { Amplify } from 'aws-amplify';
import { Router } from './pages/Router/router';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const App: FC = () => {
  return <Router />;
};

export default App;
